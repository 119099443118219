/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

.m-l-5{
    margin-left: 5px;
}
.toast-container{
  position: fixed;
}
.ng-submitted .ng-invalid{
  border-color: red !important;
}
::ng-deep ngb-rating .ng-star-inserted{
	margin-right: 1px;
    font-size: 18px;
    height: 18px;
    width: 18px;
    line-height: 16px;
	color: #EDB867;
}

.small {
    .owl-carousel{
        transform: rotate(90deg);
        width: 270px; 
        margin-top:100px;
        margin-left: -100px;
      } 
      .owl-item{
        transform: rotate(-90deg);
      }
      .owl-carousel .owl-nav{
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        top: calc(50% - 33px);
      }
      div.owl-carousel .owl-nav .owl-prev, div.owl-carousel .owl-nav .owl-next{
          font-size:36px;
          top:unset;
          bottom: 15px; 
      }
}
.d-flex{
    display: flex;
}